import { environment } from './environment';
import { HistoryNotion } from '../app/shared/services/export-history.service';

export const URI = {
  SECONDARY_MARKET: {
    PUBLISH_OFFER: `${environment.apiUrl}/private/marche-secondaire/annonce`,
    CLOSE_OFFER: (id): string => `${environment.apiUrl}/private/marche-secondaire/annonce/cloturer/${id}`,
    SUBSCRIBE_OFFER: `${environment.apiUrl}/private/marche-secondaire/annonce/interesse`,
    AUCTIONS: `${environment.apiUrl}/enchere`,
    OFFERS: `${environment.apiUrl}/private/marche-secondaire/annonce/search`,
  },
  OFFER: {
    BOOSTER_INJECTION: `${environment.apiUrl}/private/offer/booster-injection`,
    TRANSPORT_CAPACITIES: {
      STANDARDIZED_CAPACITIES: {
        GET: `${environment.apiUrl}/private/transport/standardized-capacities`,
        EXPORT: `${environment.apiUrl}/private/transport/standardized-capacities/export`,
      },
    },
    SUBSCRIBED_STORAGE_CAPACITY: {
      GET_TODAY_CAPACITIES: `${environment.apiUrl}/private/storage/aggregated-capacity/today`,
      GET_AGGREGATED_CAPACITIES: `${environment.apiUrl}/private/storage/aggregated-capacity`,
      GET_OFFERS: `${environment.apiUrl}/private/storage/subscribed-offers`,
      GET_SUBSCRIBED_CAPACITIES: `${environment.apiUrl}/private/storage/subscribed-capacities`,
      EXPORT_AGGREGATED_CAPACITIES: `${environment.apiUrl}/private/storage/aggregated-capacity/export`,
      EXPORT_SUBSCRIBED_CAPACITIES: `${environment.apiUrl}/private/storage/subscribed-capacity/export`,
    },
    SUBSCRIBED_TRANSPORT_CAPACITY: {
      GET_DETAILS: `${environment.apiUrl}/private/transport/subscribed-transport-capacity/details`,
      GET_MONITORING: `${environment.apiUrl}/private/transport/subscribed-transport-capacity/monitoring`,
      EXPORT: `${environment.apiUrl}/private/transport/subscribed-transport-capacity/export`,
    },
    TRANSPORT_CESSIONS: {
      GET: `${environment.apiUrl}/public/transport/cession`,
      EXPORT: `${environment.apiUrl}/public/transport/cession/export`,
    },
    CAPACITY_REQUEST: `${environment.apiUrl}/private/transport/capacity-subscription-request`,
    CAPACITY_REQUEST_LABELS: `${environment.apiUrl}/private/transport/capacity-subscription-request/labels`,
  },
  BILLING: {
    BALANCING_OUTSTANDING_AMOUNT: (codeEntite: number): string => `${environment.apiUrl}/private/facturation/encours/entite/${codeEntite}`,
    VARIABLE_TERMS: (codeEntite: number): string => `${environment.apiUrl}/private/facturation/entite/${codeEntite}/depassements-pic`,
    IMBALANCE_SIMULATOR: `${environment.apiUrl}/public/prix-reglement-desequilibre`,
  },
  BOOSTER_INJECTION: {
    CALENDAR: `${environment.apiUrl}/private/offer/booster-injection/calendar`,
    CONTROL_DATA: `${environment.apiUrl}/private/offer/booster-injection/validate`,
    SAVE: `${environment.apiUrl}/private/offer/booster-injection`,
    UPDATE: (id) => `${environment.apiUrl}/private/offer/booster-injection/${id}`,
    GET_CAPACITY_INJECTION: (codeEntite: number): string =>
      `${environment.apiUrl}/private/offer/booster-injection/contract?codeEntite=${codeEntite}`,
  },
  CONNECTION: {
    CONSUMPTION_DATA: {
      INTRADAY_DATA: `${environment.apiUrl}/private/connection/consumption-intra-day`,
      INTRADAY_XLS_EXPORT: `${environment.apiUrl}/private/connection/consumption-intra-day/export`,
      CONSUMPTION_HISTORY: (stationId: string, codePoint: string, dateFrom: string, dateTo: string): string =>
        `${environment.apiUrl}/private/connection/consumption-data-get?stationId=${stationId}&codePoint=${codePoint}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    },
    CONSUMPTION_RIGHT: `${environment.apiUrl}/private/connection/control-distribution-consumption`,
    GAS_QUALITY: {
      GET_BY_MONTH_AND_YEAR: (stationId: string, month: number, year: number): string =>
        `${environment.apiUrl}/private/connection/gas-quality?stationId=${stationId}&month=${month}&year=${year}`,
      GET: (stationId: string) => `${environment.apiUrl}/private/connection/gas-quality?stationId=${stationId}`,
    },
    CONTRACT_INVOICE: {
      INVOICES: `${environment.apiUrl}/private/connection/invoice`,
      GET_CONTRACT_AMENDMENTS: `${environment.apiUrl}/private/connection/contracts`,
    },
    SUBSCRIBE_PIC_CAPACITY: {
      SEND_REQUEST_MAIL: `${environment.apiUrl}/private/connection/subscribe-pic-capacity`,
    },
    WINTER_MODULATION: {
      GET: (pic: string) => `${environment.apiUrl}/private/connection/winter-modulation?pic=${pic}`,
    },
  },
  OPERATIONS: {
    MONITORING: `${environment.apiUrl}/public/logistics/monitoring-stock-winter`,
    VIGILANCE: `${environment.apiUrl}/public/logistics/vigilances`,
    LINEPACK: `${environment.apiUrl}/public/operations-transport/stock-gaz-conduite`,
    GAS_QUALITY: `${environment.apiUrl}/public/stockage/qualite-gaz`,
    PHYSICAL_FLOWS: `${environment.apiUrl}/public/stockage/flux-physique`,
    NOMINATION_HELPER: {
      GET: `${environment.apiUrl}/private/logistics/nomination-helper`,
      EXPORT: `${environment.apiUrl}/private/logistics/nomination-helper/export`,
      UBI: `${environment.apiUrl}/private/logistics/nomination-helper/ubi`,
      UBI_TRF: `${environment.apiUrl}/private/logistics/nomination-helper/ubi-trf`,
      REDUCTIONS_LIMITS: `${environment.apiUrl}/private/logistics/has-reduction-on-limits`,
      NEXT_DOOR_DAY: `${environment.apiUrl}/private/logistics/nomination-helper/next-door-day`,
      MATCHING: `${environment.apiUrl}/private/logistics/nomination-helper/matching`,
      BOOSTER: `${environment.apiUrl}/private/logistics/nomination-helper/booster`,
      TRF_LIMITS: `${environment.apiUrl}/private/gestion-operationnelle/nomination-helper/trf-limits`,
    },
    YEAR_SUMMARY_MAINTENANCES_REDUCTIONS: `${environment.apiUrl}/public/logistics/maintenances-reductions/year-summary`,
    TRF_OPERATIONAL_CAPACITIES: {
      GET: `${environment.apiUrl}/private/transport/trf-operational-capacities`,
      EXPORT: `${environment.apiUrl}/private/transport/trf-operational-capacities/export`,
    },
    MAINTENANCES_REDUCTIONS: {
      GET: `${environment.apiUrl}/public/logistics/maintenances-reductions`,
      LAST_UPDATE: `${environment.apiUrl}/public/logistics/maintenances-reductions/lastupdate`,
      GET_STORAGE: `${environment.apiUrl}/public/logistics/maintenances-reductions/storage`,
    },
    LOCATIONAL_SPREAD: `${environment.apiUrl}/public/logistics/locational-spread`,
    INTRA_DAY: {
      TEREGA_DATA: {
        GET_PUBLIC: (gasDay: string) => `${environment.apiUrl}/public/logistics/intra-days/${gasDay}`,
        GET_EXPORT: `${environment.apiUrl}/logistics/intraday-data-terega/export`,
        GET_XLS_EXPORT: `${environment.apiUrl}/logistics/intraday-data-terega/xls-export`,
        GET_EXPORT_PUBLIC: (dateFrom: string, dateTo: string) =>
          `${environment.apiUrl}/public/logistics/intra-days/export?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        GET_PRIVATE: `${environment.apiUrl}/private/logistics/intra-day-terega`,
      },
      DISTRIBUTION_DATA: {
        GET_PUBLIC: `${environment.apiUrl}/public/logistics/intra-days-distribution`,
        GET_PUBLIC_EXPORT: `${environment.apiUrl}/public/logistics/intra-days-distribution/export`,
        GET_PRIVATE: `${environment.apiUrl}/private/logistics/intra-days-distribution`,
        GET_PRIVATE_DETAILS: (gasDay: string): string => `${environment.apiUrl}/private/logistics/intra-days-distribution/${gasDay}`,
        GET_PRIVATE_EXPORT: `${environment.apiUrl}/private/logistics/intra-days-distribution/export`,
      },
    },
    NOMINATIONS: {
      GET_GASDAYS: `${environment.apiUrl}/public/logistics/notification`,
      GET_EXPORT_DATA: `${environment.apiUrl}/public/logistics/notification/export`,
    },
    FORECAST: {
      PIC_PITD: `${environment.apiUrl}/public/logistics/forecast/pic-pitd`,
      PITD: {
        PUBLIC: `${environment.apiUrl}/public/logistics/forecasts/pitd`,
        PUBLIC_EXPORT: `${environment.apiUrl}/public/logistics/forecasts/pitd/export`,
        GRAPH: `${environment.apiUrl}/public/logistics/forecast/pitd/latest`,
        PRIVATE: `${environment.apiUrl}/private/logistics/forecasts/pitd`,
        GET_EXPORT_PRIVATE: `${environment.apiUrl}/private/logistics/forecasts/pitd/export`,
      },
    },
    PURCHASES_SALES: {
      GET: `${environment.apiUrl}/public/logistics/purchases-sales`,
    },
    ACTIONS_ON_MARKET: `${environment.apiUrl}/public/logistics/actions-on-market`,
    STORAGE_OPERATIONAL_MONITORING: {
      GET: `${environment.apiUrl}/private/stockage/operational-monitoring`,
      GET_CHART: (codeAti: string, dateFrom: string, dateTo: string): string =>
        `${environment.apiUrl}/private/stockage/operational-monitoring/stock-level?codeAti=${codeAti}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      GET_EXPORT: `${environment.apiUrl}/private/stockage/operational-monitoring/export`,
    },
    PROGRAMMING: {
      GET: `${environment.apiUrl}/private/transport/programmation`,
      EXPORT: `${environment.apiUrl}/private/transport/programmation/export`,
    },
    CAPACITIES: {
      GET_DAILY: `${environment.apiUrl}/public/transport/capacities/daily`,
      GET_MONTHLY: `${environment.apiUrl}/public/transport/capacities/monthly`,
      GET_T0_COME: `${environment.apiUrl}/public/transport/capacities/to-come`,
      GET_MAINTENANCE: `${environment.apiUrl}/public/events/terega-maintenances/days`,
      EXPORT_DAILY: `${environment.apiUrl}/public/transport/capacities/daily/export`,
      EXPORT_MONTHLY: `${environment.apiUrl}/public/transport/capacities/monthly/export`,
    },
    ALLOCATED_BALANCING: {
      BALANCING: {
        GET: `${environment.apiUrl}/private/transport/balancing`,
        EXPORT: `${environment.apiUrl}/private/transport/allocation-balancing/balancing/export`,
      },
      ALLOCATION: {
        GET: `${environment.apiUrl}/private/transport/allocation`,
        EXPORT: `${environment.apiUrl}/private/transport/allocation-balancing/allocation/export`,
      },
    },
    JOINT_DECLARATION: {
      PIC: {
        GET: `${environment.apiUrl}/private/transport/joint-declaration/pic`,
        EXPORT: `${environment.apiUrl}/private/transport/joint-declaration/pic/export`,
        CHECK: `${environment.apiUrl}/private/transport/joint-declaration/pic/check`,
        CREATE: `${environment.apiUrl}/private/transport/joint-declaration/pic/create`,
        PATCH: `${environment.apiUrl}/private/transport/joint-declaration/pic`,
        RECEIPT: `${environment.apiUrl}/private/transport/joint-declaration/pic/receipt`,
        LABELS: `${environment.apiUrl}/private/transport/joint-declaration/pic/labels`,
      },
      PITD: {
        GET: `${environment.apiUrl}/private/transport/joint-declaration/pitd`,
        EXPORT: `${environment.apiUrl}/private/transport/joint-declaration/pitd/export`,
        RECEIPT: `${environment.apiUrl}/private/transport/joint-declaration/pitd/receipt`,
      },
    },
    GAS_ALLOCATION: {
      GET: `${environment.apiUrl}/public/stockage/gas-allocation`,
      EXPORT: `${environment.apiUrl}/public/stockage/gas-allocation/export`,
    },
    COMMERCIAL_CONGESTION: {
      AUCTION_PREMIUM: {
        GET: `${environment.apiUrl}/public/transport/commercial-congestion/auction-premium`,
        EXPORT: `${environment.apiUrl}/public/transport/commercial-congestion/auction-premium/export`,
      },
      ADDITIONAL_CAPACITIES: {
        GET: `${environment.apiUrl}/public/transport/commercial-congestion/additional-capacities`,
        EXPORT: `${environment.apiUrl}/public/transport/commercial-congestion/additional-capacities/export`,
      },
      UNSATISFIED_DEMANDS: {
        GET: `${environment.apiUrl}/public/transport/commercial-congestion/unsatisfied-demands`,
        EXPORT: `${environment.apiUrl}/public/transport/commercial-congestion/unsatisfied-demands/export`,
      },
    },
    STOCK: `${environment.apiUrl}/public/stockage/indicateurs`,
  },
  COMMON: {
    PRIVATE_DOWNLOAD: `${environment.apiUrl}/shared/private/download-file`,
    GET_ENTITY: (id: string): string => `${environment.apiUrl}/entite/${id}`,
    ENTITY: `${environment.apiUrl}/entite`,
    EVENTS: {
      PRIVATE: {
        GET: `${environment.apiUrl}/private/events`,
        CREATE: `${environment.apiUrl}/private/events`,
        UPDATE: (id: string): string => `${environment.apiUrl}/private/events/${id}`,
      },
    },
    GET_STATIONS: `${environment.apiUrl}/private/referentials/stations`,
    GET_STATION_POINTS: `${environment.apiUrl}/private/referentials/station/points`,
    GET_POINTS: `${environment.apiUrl}/private/referentials/points`,
    GET_POINTS_BY_ENTITY: `${environment.apiUrl}/private/referentials/points-by-entity`,
    GET_CAD: `${environment.apiUrl}/private/referentials/cad`,
    LAST_UPDATE: `${environment.apiUrl}/shared/last-update`,
    GET_CONFIGURATION: `${environment.apiUrl}/public/referentials/configuration`,
    UPDATE_CONFIGURATION: `${environment.apiUrl}/private/referentials/configuration`,
    HISTORY: {
      GET: `${environment.apiUrl}/shared/history`,
      DOWNLOAD: (notion: HistoryNotion, year: number, lang = 'fr') => `${environment.apiUrl}/shared/history/${notion}/${lang}/${year}`,
    },
    GET_FINAL_DATA: `${environment.apiUrl}/shared/private/final-data`,
  },
  EVENTS: {
    PRIVATE: `${environment.apiUrl}/private/events`,
    PUBLIC: `${environment.apiUrl}/public/events`,
    READ: `${environment.apiUrl}/private/events/status`,
    COUNT: (entity: number): string => `${environment.apiUrl}/private/events/search/${entity}/count`,
    GET: (id: string): string => `${environment.apiUrl}/private/events/${id}`,
  },
  INVOICING: {
    DOCUMENTS: {
      DOWNLOAD: (codeAti: number, invoiceNumber: number, fileId: string): string =>
        `${environment.apiUrl}/private/entities/${codeAti}/invoices/${invoiceNumber}/documents/${fileId}`,
    },
  },
  OUR_API: {
    SUBMIT_REQUEST_FORM: `${environment.apiUrl}/public/our-api/submit-request`,
    GET_AVAILABLE: `${environment.apiUrl}/public/our-api/get-available`,
  },
  UPLOAD_FILE: `${environment.apiUrl}/shared/upload`,
};
